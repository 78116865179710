.cursor-pointer{
    cursor: pointer;
}

.font-12{
    font-size: 12px;
}

.font-14{
    font-size: 14px;
}

.font-16{
    font-size: 16px;
}

.font-18{
    font-size: 18px;
}

.font-20{
    font-size: 20px;
}

.cover-container__list{
    max-width: 8rem;
}

.container-queue_list{
    display: flex;
    flex-direction: column;
}

.trackList{
    display: flex;
    border-radius: 1em 1em 0 0;
    background-color: rgba(74, 74, 74, .35);
    max-height: 16rem;
}

.trackList-head, th{
        :first-child{
            border-radius: 1em 0 0 0;
        }
        :last-child{
            border-radius: 0 1em 0 0;
        }
        padding-top: .5rem;
        background-color:  rgba(14, 14, 14) !important;
}

.list_container-scroll{
    overflow-y: scroll;
}

.onAir {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}


/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #1DB954; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #199946; 
  }

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }